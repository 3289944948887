import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Layout from '../components/App/Layout'
import Navbar from '../components/Common/Navbar'
import PageBanner from '../components/Common/PageBanner'
import Footer from '../components/Common/Footer'
import NeedHelp from '../components/NeedHelp/NeedHelp'

const ComponentName = ({ data }) => {
  const { title, subIntro, serviceSubItem: items, Meta, TwitterMeta } = data.service

  return (
    <Layout metaTags={Meta} twitterCard={TwitterMeta}>
      <Navbar />

      <PageBanner
        pageTitle={title}
        homePageText="Services"
        homePageUrl="/services"
        activePageText={title}
      />

      <section className="service-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="service-details-desc">
                <ReactMarkdown source={subIntro} />
              </div>
            </div>
          </div>

          {items.map(({ title, description, iconClass }, index) => (
            <div className="row pt-70 service-sub-item" key={index}>
              <div className="col-sm-2 col-12 text-center">
                <i className={`${iconClass} icon pb-70`} />
              </div>
              <div className="col-sm-8 col-12">
                <h2>{title}</h2>
                <ReactMarkdown source={description} />
              </div>
            </div>
          ))}
        </div>
      </section>

      <NeedHelp/>

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleService($slug: String) {
    service: strapiServices(slug: { eq: $slug }) {
      slug
      title
      content
      subIntro
      serviceSubItem {
        title
        iconClass
        description
      }
      Meta {
        title
        keywords
        description
      }
      TwitterMeta {
        card
      }
    }
  }
`

export default ComponentName
